.parent-container {
    display: flex;
}

.patient-create{
    flex: 4;
    margin-top: 0px;
    width: 100%;

}

.newPatientForm{
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    padding: 30px;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 35px -6px rgba(0,0,0,0.09);
    margin: 20px;
}

/* Below is 403 code  */

.page-content403{
    display: flex;
    justify-content: center;
    align-items: center ;
    flex-direction: column;
}

.page-404 {
  text-align: center;
  padding: 2rem;
}

.error-code {
  font-size: 6rem;
  font-weight: bold;
  color: #e74c3c;
  margin-bottom: 0.5rem;
}

.error-message {
  font-size: 2rem;
  color: #34495e;
  margin-bottom: 1rem;
}

.error-description {
  font-size: 1.2rem;
  color: #7f8c8d;
  margin-bottom: 2rem;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #3498db;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #2980b9;
}

.btn-secondary {
  background-color: #ecf0f1;
  color: #34495e;
  border: 1px solid #bdc3c7;
}

.btn-secondary:hover {
  background-color: #bdc3c7;
}