@tailwind base;
@tailwind components;
@tailwind utilities;

* {    

/*  @font-face {
font-family: "Mulish";
src: local("Mulish"),
 url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&family=Rokkitt:wght@600;700&display=swap") format("truetype");
font-weight: bold;
} */
}


.modal {
  display: none;
}

.modal.active {
  display: flex;
}