.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 1rem;
}

.error-content {
  max-width: 400px;
  width: 100%;
}

.error-code {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.error-message {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 1rem;
}

.error-description {
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 1.5rem;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #3498db;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #2980b9;
}

.btn-secondary {
  background-color: #ecf0f1;
  color: #34495e;
  border: 1px solid #bdc3c7;
}

.btn-secondary:hover {
  background-color: #bdc3c7;
}

.unauthorised .error-code {
  color: #e74c3c;
}

.page-404 .error-code {
  color: #3498db;
}
