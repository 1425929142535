.mailbox-container {
  display: flex;
  flex-direction: column;
  height: 83vh; /* Subtract 5px for the top menu padding */
  width: 100%;
  background-color: #f5f7fa;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden; /* Add this to prevent scrolling on the main container */
}

.mailbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0; /* Add this to prevent the header from shrinking */
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left h1 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-right: 20px;
}

.mailbox-dropdown {
  position: relative;
}

.dropdown-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #f0f2f5;
  transition: background-color 0.2s;
}

.dropdown-header:hover {
  background-color: #e4e6e9;
}

.dropdown-header span {
  margin: 0 8px;
  font-weight: 500;
}

.dropdown-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.dropdown-options.show {
  display: block;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  background-color: #f0f2f5;
}

.dropdown-item span {
  margin-left: 8px;
}

.header-right {
  display: flex;
  align-items: center;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  border-radius: 20px;
  padding: 6px 12px;
  margin-right: 16px;
}

.search-icon {
  color: #65676b;
  margin-right: 8px;
}

.search-bar input {
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 14px;
}

.compose-button {
  display: flex;
  align-items: center;
  background-color: #0084ff;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.compose-button:hover {
  background-color: #0073e6;
}

.compose-button svg {
  margin-right: 8px;
}

.mailbox-content {
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 85px); /* Adjust for 80px header + 5px top menu padding */
}

.mailbox-sidebar {
  width: 300px;
  min-width: 300px;
  background-color: #ffffff;
  border-right: 1px solid #e4e6e9;
  overflow-y: auto; /* This allows scrolling within the sidebar if needed */
  height: 100%; /* Ensure the sidebar takes full height of its container */
}

.email-list {
  padding: 12px;
}

.email-item {
  display: flex;
  align-items: flex-start; /* Change from center to flex-start */
  padding: 12px 16px; /* Increase horizontal padding */
  margin-bottom: 8px; /* Add space between items */
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.email-item:hover {
  background-color: #f0f2f5;
}

.email-item.selected {
  background-color: #e7f3ff;
}

.email-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0084ff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: 16px; /* Increase space between avatar and text */
  flex-shrink: 0; /* Add this line */
  font-size: 16px; /* Add this line */
  line-height: 1; /* Add this line */
}

.email-details {
  flex: 1;
  min-width: 0; /* Add this line */
}

.email-subject {
  font-weight: 600;
  margin-bottom: 2px; /* Reduce space between subject and sender */
  font-size: 14px; /* Slightly increase font size */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email-sender {
  font-size: 12px;
  color: #65676b;
  margin-bottom: 2px; /* Reduce space between sender and preview */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email-preview {
  font-size: 13px;
  color: #65676b;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4; /* Slightly increase line height for better readability */
}

.email-content {
  flex: 1;
  padding: 24px;
  overflow-y: auto; /* This allows scrolling within the email content if needed */
  width: calc(100% - 300px);
  height: 100%; /* Ensure the email content takes full height of its container */
}

.selected-email h2 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #333;
}

.selected-email p {
  margin-bottom: 16px;
  line-height: 1.6;
  color: #4a4a4a;
}

.no-email-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #65676b;
  font-size: 16px;
}

.meeting-details {
  margin-top: 24px;
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 16px;
}

.meeting-link {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.meeting-link svg {
  color: #0084ff;
  margin-right: 8px;
  font-size: 20px;
}

.meeting-link a {
  color: #0084ff;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

.meeting-link a:hover {
  text-decoration: underline;
}

.meeting-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.meeting-date,
.meeting-time {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.meeting-date svg,
.meeting-time svg {
  color: #65676b;
  margin-right: 8px;
  font-size: 16px;
}

.meeting-date span,
.meeting-time span {
  color: #333;
  font-size: 14px;
}